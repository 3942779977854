import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Modal, Dropdown, Container } from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import numberWithCommas from "../utils/numbersWithComma";
import { getUserBet } from "../redux/sports/actions";

import Profile from "../assets/images/icons/menu/profile.svg";
import Deposit from "../assets/images/icons/menu/wallet.svg";
import Payout from "../assets/images/icons/menu/payout.svg";
import History from "../assets/images/icons/menu/bets.svg";
import Referral from "../assets/images/icons/menu/referral.svg";
import Bonus from "../assets/images/icons/menu/bonus.svg";
import Statement from "../assets/images/icons/menu/statement.svg";
import Logout from "../assets/images/icons/menu/logout.png";
import HomeIcon from "../assets/images/icons/header/Home.png";
import VIPIcon from "../assets/images/icons/header/premium-quality.png";
import AffiliatesIcon from "../assets/images/icons/header/affiliate.png";
import PromotionIcon from "../assets/images/icons/header/Promotion.png";
import SupportsIcon from "../assets/images/icons/header/Support.png";
import DownloadIcon from "../assets/images/icons/header/APKDownload.png";
import DownloadApk from "../assets/images/icons/header/download-apk.webp";
import LanguageChange from "../views/components/LanguageChange";

import NotificationIcon from "../assets/images/icons/bell.png";
import { getNotifications } from "../redux/app/actions";

const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const headerPages = [
    "betby",
    "exchange",
    "qtechbetgamestv",
    "casino",
    "home",
    "promotions",
    "blog",
    "/",
    "/home",
    "qtech",
  ];

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    let intervalID = setInterval(() => {}, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="Announcement">
          <div className="latest">
            <img src={NotificationIcon} alt="NotificationIcon" />
          </div>
          <marquee>
            {" "}
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
        </div>
      )) ||
        null}

      <header className="header aftrlgn">
        <Container fluid>
          <div className="top_head">
            <div className="d-flex justify-content-between align-items-center">
              <div className="language d-none">
                <LanguageChange />
              </div>

              <div className="d-flex align-items-center">
                <div className="head-tabs d-md-block d-none">
                  <ul>
                    <li>
                      <a
                        href="/"
                        className={activePage == "home" ? "active" : ""}
                      >
                        <img src={HomeIcon} alt="HomeIcon" />
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className={activePage == "" ? "active" : ""}>
                        <img src={PromotionIcon} alt="PromotionIcon" />
                        <span>Promotions</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/kingmaker"
                        className={activePage == "" ? "active" : ""}
                      >
                        <img src={VIPIcon} alt="VIPIcon" />
                        <span>Kingmaker</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className={activePage == "" ? "active" : ""}>
                        <img src={AffiliatesIcon} alt="VIPIcon" />
                        <span>Affiliates</span>
                      </a>
                    </li>
                    <li>
                      <a href="" className={activePage == "" ? "active" : ""}>
                        <img src={SupportsIcon} alt="VIPIcon" />
                        <span>Support</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dl.dropbox.com/scl/fi/feojb5kml5ay1bac93yks/Winz11.apk?rlkey=9ju6k7jbrsnmqpbwilgzfixqy&st=jelfngk9&dl=0"
                        download="https://dl.dropbox.com/scl/fi/feojb5kml5ay1bac93yks/Winz11.apk?rlkey=9ju6k7jbrsnmqpbwilgzfixqy&st=jelfngk9&dl=0"
                        className={activePage == "" ? "active" : ""}
                      >
                        <img src={DownloadIcon} alt="VIPIcon" />
                        <span>App download</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="logo">
                <a href="/home">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                  {/* <img src={logo} alt="Logo" /> */}
                </a>
              </div>

              <div className="headerRight">
                {/* <CurrencyDropdown textColor={"#ffffff"} /> */}

                <Dropdown align="end" className="d-none d-md-block">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={Profile} alt="profile" /> {user?.mstruserid}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/profile">
                      <img src={Profile} alt="profile" />
                      <span>Account Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/gatewaylist">
                      <img src={Deposit} alt="deposit" />
                      <span>Deposit</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/withdraw">
                      <img
                        src={Payout}
                        alt="Payout"
                        style={{ padding: "0 2px" }}
                      />
                      <span>Withdraw</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/mybets">
                      <img src={History} alt="bets" />
                      <span>My Bets</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/referral">
                      <img
                        src={Referral}
                        alt="referral"
                        style={{ padding: "0 2px" }}
                      />
                      <span>Referral</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/bonus">
                      <img src={Bonus} alt="bonus" />
                      <span>Bonuses</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/cashier">
                      <img src={Statement} alt="statement" />
                      <span>Account Statement</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/"
                      onClick={() => {
                        dispatch(logoutUser());
                      }}
                      className="logout"
                    >
                      <img
                        src={Logout}
                        alt="logout"
                        style={{ width: "18px" }}
                      />
                      <span>Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* {renderBalance()} */}
                <div className="balance-info">
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="balance-info">
                      Balance {numberWithCommas(wallet?.balance)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <span>Balance</span>
                        <strong>{numberWithCommas(wallet?.balance)}</strong>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <span>Cash</span>
                        <strong>{numberWithCommas(wallet?.cash || 0)}</strong>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <span>Bonus</span>
                        <strong>{numberWithCommas(wallet?.coins)}</strong>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="balance">
                    <div className="bonus-bal">
                      <span>Balance</span>
                      <span>{numberWithCommas(wallet?.balance)}</span>
                    </div>
                    <div className="main-bal">
                      <span>Cash</span>
                      <strong>{numberWithCommas(wallet?.cash || 0)}</strong>
                    </div>
                    <div className="bonus-bal">
                      <span>Bonus</span>
                      <span>{numberWithCommas(wallet?.coins)}</span>
                    </div>
                  </div> */}
                </div>

                <div className="DepositBtn d-md-block d-none">
                  <Button variant="btn btn-primary" href="/gatewaylist">
                    Deposit
                  </Button>
                </div>
                <a
                  href="/"
                  onClick={() => {
                    dispatch(logoutUser());
                  }}
                  className="logoutMobile d-md-none d-flex"
                >
                  <img src={Logout} alt="logout" style={{ width: "18px" }} />
                  <span>Logout</span>
                </a>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
